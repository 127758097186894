import {useCallback, useEffect, useRef, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { Modal } from "flowbite-react";
import Button from "../../../../components/button/button";
import TextBox from "../../../../components/forms/text-box";
import TextArea from "../../../../components/forms/text-area";
import Message from "../../../../components/toast-message";
import {useAppInfo} from "../../../../helpers/hooks/common-hook";
import {getAgentAction, saveAgentActionSettings} from "../../../../services/prompt-ai";
import {WEBHOOK_DEFAULT_ACTION_CONFIG} from "../../../agent/agent-actions/action-settings/common-consts";
import ActionParameters from "../../../../components/action-parameter-row";
import FormLoader from "../../../../components/form-loader/form-loader";
import {validateActionName} from "../../../../helpers/utils/text-util";

function JourneyCreateModel({onCompletion, onCancel, journeyId}) {
    const rootRef = useRef(null);
    const navigate = useNavigate();
    const { appId } = useAppInfo();
    const [formData, setFormData] = useState({
        name: undefined,
        description: undefined,
        parameters: [],
        actionType: 'workflow',
        resourceId: 'workflow'
    });

    const [createdJourneyId, setCreatedJourneyId] = useState();

    const [dataLoadingState, setDataLoading] = useState({
        processing: false,
        success: false,
        failed: false,
        message: null
    });

    const [error, setError] = useState({
        name: false,
        nameErrorMessage: null,
        description: false
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    useEffect(() => {
        if (createdJourneyId) {
            setTimeout(() => {
                onCompletion()
                navigate(`/app/${appId}/journey/${createdJourneyId}`)
            }, 1500)
        }
    }, [createdJourneyId]);

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        const newForm = {...formData}
        newForm[targetName] = targetValue
        setFormData(newForm);
    });

    const validateForm = () => {
        let valid = true;
        if (!formData.name) {
            error.name = true;
            valid = false;
        }
        // else if (!validateActionName(formData.name)) {
        //     error.name = true;
        //     error.nameErrorMessage = 'Journey name cannot contain spaces or special characters'
        //     valid = false;
        // }
        else {
            error.name = false;
        }

        if (!formData.description) {
            error.description = true;
            valid = false;
        } else {
            error.description = false;
        }

        setError({...error})
        return valid;
    }

    const updateFormState = (processing, success, failed, message) => {
        setFormState(Object.assign({}, {processing, success, failed, message}))
    }

    const updateDataLoadingState = (processing, success, failed, message) => {
        setDataLoading(Object.assign({}, {processing, success, failed, message}))
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if (!formState.processing && validateForm()) {
            updateFormState(true, false, false, );
            const { status, response } = await saveAgentActionSettings(appId, formData);
            if (status === 200) {
                setCreatedJourneyId(response.id)
                updateFormState(false, true, false, 'Successfully saved action');
                // onCompletion()
                return true;
            } else {
                updateFormState(false, false, true, 'Unable to save action settings');
                return false
            }
        }
    };

    async function fetchActionSettings() {
        updateDataLoadingState(true, false, false);
        const resp = await getAgentAction(appId, journeyId);
        console.log("fetchChatBotSettings", resp)
        if (resp) {
            setFormData(resp);
        }
        updateDataLoadingState(false, true, false);
    }

    useEffect( () => {
        if (journeyId) {
            fetchActionSettings()
        }
    }, [journeyId]);

    const buttonText = journeyId? "Update Journey": "Create Journey";
    return (
        <div ref={rootRef}>
            <Modal show={true} size={journeyId? "4xl": "md"} popup onClose={onCancel} root={rootRef.current ?? undefined}>
                <Modal.Header></Modal.Header>
                <Modal.Body>

                    <form onSubmit={handleOnSubmit}>
                        <div className="space-y-6">
                            <h3 className="text-xl font-medium text-gray-900 dark:text-white">{journeyId?'Update Journey':'Create New Journey'}</h3>

                            {(formState.processing || dataLoadingState.processing) && <FormLoader relative={true}/>}

                            {formState.success && <Message text="Successfully Created New Journey" type="success"/>}

                            {formState.failed && <Message text={formState.message} type="failure"/>}

                            <TextBox
                                type="text"
                                name="name"
                                id="name"
                                label="Name*"
                                value={formData.name}
                                onChange={handleChange}
                                error={error.name}
                                errorMessage={error.nameErrorMessage}
                            />

                            <TextArea
                                type="text"
                                name="description"
                                id="description"
                                label="Description for AI"
                                value={formData.description}
                                placeholder={`Give a prompt for what this journey is for.\nExample: This is for booking a table reservation for a restaurant.`}
                                onChange={handleChange}
                                rows={3}
                                error={error.description}
                            />

                            {/*{journeyId &&*/}
                            {/*    <ActionParameters actionConfig={formData} setActionConfig={setFormData}/>*/}
                            {/*}*/}


                            <div className="w-full flex flex-row gap-2 justify-end">
                                <Button color="default" text="Cancel" onClick={onCancel}/>
                                {formState.processing?
                                    <Button type="button" text={buttonText} loading={true}/>
                                    :
                                    <Button type="submit" text={buttonText}/>
                                }

                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default JourneyCreateModel
