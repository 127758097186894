import React, { useState, useEffect, createContext } from "react";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";
import { TbLogout2 } from "react-icons/tb";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiOutlineUserCircle } from "react-icons/hi";

import mainLogo from "../../../../assets/images/logo/main-color.png";
import { cn } from "../../../../helpers/utils/css-utils";
import { getMyUserProfile, updateUserProfile } from "../../../../services/user/user-service";

export const SidebarContext = createContext();

const Sidebar = ({ children }) => {
  const [expanded, setExpanded] = useState(false);
  const [isPopover, setIsPopover] = useState(false);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getMyUserProfile();
        console.log(profile)
        setUserProfile(profile.response);
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleMouseEnter = () => {
    setExpanded(true);
  };

  const handleMouseLeve = () => {
    setExpanded(false);
    setIsPopover(false);
  };

  const handleUserPopover = () => {
    setIsPopover((prev) => !prev);
  };

  const handleSignOut = async () => {
    await Auth.signOut();
    window.location = "/";
  };

  return (
    <>
      <aside
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeve}
        className={cn(
          "z-[12] fixed h-screen w-16 transition-all duration-500 hover:cursor-pointer shadow-lg",
          {
            "w-64": expanded,
          }
        )}
      >
        <nav className="h-full flex flex-col bg-gray-50 dark:bg-gray-950 border-r border-r-gray-200 dark:border-r-gray-700">
          <div className="px-3 p-4 flex justify-between items-center">
            <div className="flex items-center gap-3">
              <div className="w-9 h-9 flex-none rounded-md">
                <img
                  src={mainLogo}
                  alt="BotCircuits2"
                  className="w-full h-full object-cover flex-shrink-0"
                />
              </div>
              <span
                className={cn(
                  "font-bold text-lg w-0 tracking-wide text-gray-800 dark:text-gray-100 truncate opacity-0 transition-all",
                  {
                    "w-52 opacity-100": expanded,
                  }
                )}
              >
                BotCircuits
              </span>
            </div>
          </div>

          <SidebarContext.Provider value={{ expanded }}>
            <ul className="flex-1 px-3">{children}</ul>
          </SidebarContext.Provider>

          <div className="border-t border-gray-200 dark:border-t-gray-700 flex p-3">
            <div className="w-9 h-9 flex items-center justify-center flex-shrink-0 bg-indigo-700 text-white rounded-md">
              <span className="font-medium text-lg">
                {userProfile ? userProfile.name.charAt(0).toUpperCase() : "A"}
              </span>
            </div>
            <div
              className={`flex justify-between items-center transition-all ${
                expanded
                  ? "w-52 ml-3 overflow-visible opacity-100"
                  : "w-0 overflow-hidden opacity-0"
              } `}
            >
              <div className="leading-4">
                <h4 className="font-semibold text-gray-800 dark:text-gray-100">
                  {userProfile ? userProfile.name : "..."}
                </h4>
                <span className="text-xs text-gray-500">
                  {userProfile ? userProfile.email : "..."}
                </span>
              </div>
              {/* Popover List */}
              <div className="relative overflow-visible z-50">
                <button
                  onClick={handleUserPopover}
                  className="w-7 h-7 flex items-center flex-none justify-center rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer"
                >
                  <BsThreeDotsVertical size={17} className="text-gray-500" />
                </button>
                {expanded && isPopover && (
                  <ul
                    className="
                  w-52 absolute right-[80%] bottom-10 bg-white dark:bg-gray-700 rounded-lg
                  border dark:border-gray-800 shadow-[0_30px_60px_-12px_rgba(0,0,0,0.1)] overflow-hidden"
                  >
                    <li
                      role="button"
                      onClick={() => {
                        window.location = "/user-settings/profile";
                      }}
                      className="px-2 py-1 text-sm flex items-center gap-2 group dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-800 focus:opacity-80 transition-all"
                    >
                      <span className="w-9 h-9 flex items-center justify-center text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-100">
                        <HiOutlineUserCircle size={21} />
                      </span>
                      <span>Account</span>
                    </li>
                    <li
                      role="button"
                      onClick={handleSignOut}
                      className="px-2 py-1 text-sm flex items-center gap-2 group dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-800 focus:opacity-80 transition-all"
                    >
                      <span className="w-9 h-9 flex items-center justify-center  text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-100">
                        <TbLogout2 size={21} />
                      </span>
                      <span>Sign out</span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </nav>
      </aside>
    </>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  children: PropTypes.element,
};
