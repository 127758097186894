import { useEffect, useState } from 'react';
import Button from '../../../components/button/button';
import SelectInput from "../../../components/forms/select";
import TextArea from "../../../components/forms/text-area";
import PageContainerV2 from '../../../components/page-container/page-container-v2';
import Message from "../../../components/toast-message";
import { useAppInfo } from '../../../helpers/hooks/common-hook';
import { listAuthConnes } from "../../../services/model/model-config-service";
import { getAgentCoreSettings, saveAgentCoreSettings } from "../../../services/prompt-ai";
import SectionHeading from '../../channels/_components/section-heading';
import { DEFAULT_LANGUAGE, LANGUAGES } from "./languages";

const defaultAuthConfig = { "connector": null, "prompt": null };

function ActionSettings() {
  const { appId } = useAppInfo();
  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: ''
  });

  const [settingsSaveState, setSettingsSaveState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: ''
  });

  const [chatBotSetting, setChatBotSetting] = useState({
    agentName: '',
    agentDescription: '',
    botLanguage: DEFAULT_LANGUAGE,
    authConfig: defaultAuthConfig
  });

  const updateFormState = (processing, success, failed, errorMessage) => {
    setFormState(Object.assign({}, { processing, success, failed, errorMessage }))
  }

  const updateSettingsSaveState = (processing, success, failed, errorMessage) => {
    setSettingsSaveState(Object.assign({}, { processing, success, failed, errorMessage }))
  }

  async function fetchChatBotSettings() {
    updateFormState(true, false, false);
    const resp = await getAgentCoreSettings(appId);
    if (resp) {
      resp.authConfig = resp.authConfig || defaultAuthConfig;
      setChatBotSetting(resp);
    }
    updateFormState(false, true, false);
  }

  useEffect(() => {
    fetchChatBotSettings();
  }, []);

  const onClickSaveConfig = async event => {
    event.preventDefault();
    if (!formState.processing) {
      updateSettingsSaveState(true, false, false);
      try {
        let { status, response } = await saveAgentCoreSettings(appId, chatBotSetting);
        if (status === 200) {
          updateSettingsSaveState(false, true, false);
        } else {
          updateSettingsSaveState(false, false, true, response);
        }

      } catch (e) {
        updateSettingsSaveState(false, false, true, 'Unable to save settings');
      }
    }
  }

  const handleChange = event => {
    const targetName = event.target.name;
    const targetValue = event.target.value;
    const newChatBotSetting = { ...chatBotSetting };
    newChatBotSetting[targetName] = targetValue
    setChatBotSetting(newChatBotSetting);
  };

  const [authConnectors, setAuthConnectors] = useState([]);
  const [authConnectorsLoadingState, setAuthConnectorsLoadingState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: '',
    errorMessage: ''
  });

  const updateAuthConnectorsLoading = ({ processing, success, failed, message, errorMessage }) => {
    setAuthConnectorsLoadingState({ ...processing, success, failed, message, errorMessage })
  }

  async function fetchConnectors() {
    updateAuthConnectorsLoading({
      processing: true
    });
    const { data } = await listAuthConnes(appId);
    if (data && Array.isArray(data)) {
      setAuthConnectors(data);
    }
    updateAuthConnectorsLoading({
      processing: false
    });
  }

  useEffect(() => {
    fetchConnectors();
  }, []);

  const authConnectorsOptions = authConnectors.map((conn) => ({ value: conn.id, label: conn.id }));
  authConnectorsOptions.push({ value: null, label: "No Auth" })

  return (
    <PageContainerV2
      title="Instructions"
      subtitle="Conversation Instruction"
      pageType="contents"
      pageSidebar={true}
    >
      <div className="flex h-full flex-1 flex-grow">
        <div className="flex flex-col flex-1">
          <SectionHeading title="Agent Prompt">
            <div className="flex flex-row gap-2">
              <Button
                color="default"
                type="button"
                text="Reset"
              />

              {(formState.processing || settingsSaveState.processing || authConnectorsLoadingState.processing) ?
                <Button
                  type="submit"
                  text="Save"
                  loading={true}
                />
                :
                <Button
                  type="submit"
                  text="Save"
                  onClick={onClickSaveConfig}
                />
              }

            </div>
          </SectionHeading>
          <div className="flex flex-col space-y-8 px-5 w-full">
            <div className="py-4 mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-6 md:gap-6">
                <form className="mt-5 md:mt-0 col-span-6 md:col-span-6 pb-6 pr-6" onSubmit={onClickSaveConfig}>
                  {settingsSaveState.success && <div className=""><Message text="Your settings have been successfully saved" type="success" /> </div>}
                  {settingsSaveState.errorMessage && <div className=""><Message text="Unable to save settings. Please tty again" type="failure" /></div>}

                  {/*<div className="bg-white pl-4 py-5 sm:grid sm:grid-cols-5 sm:gap-4 sm:pl-6 items-center">*/}
                  {/*    <dt className="text-sm font-medium text-gray-500">Agent Name</dt>*/}
                  {/*    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">*/}
                  {/*        <input*/}
                  {/*            className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"*/}
                  {/*            type="text"*/}
                  {/*            name="agentName"*/}
                  {/*            id="agentName"*/}
                  {/*            autoComplete={false}*/}
                  {/*            onChange={handleChange}*/}
                  {/*            placeholder="Friendly name for your agent"*/}
                  {/*            value={chatBotSetting.agentName}*/}
                  {/*        />*/}
                  {/*    </dd>*/}
                  {/*</div>*/}

                  <TextArea
                    type="text"
                    name="agentDescription"
                    id="agentDescription"
                    // label="Instructions"
                    value={chatBotSetting.agentDescription}
                    placeholder="Eg: You are the Customer Support Bot. Your role is to assist users with their inquiries and resolve issues."
                    onChange={handleChange}
                    rows={15}
                  />

                  {/*<SelectInput*/}
                  {/*    rootClass="pt-6 w-1/3"*/}
                  {/*    label="Auth Connector"*/}
                  {/*    value={chatBotSetting.authConfig.connector}*/}
                  {/*    onChange={event => { handleAuthConnectorChange(event)}}*/}
                  {/*    options={authConnectorsOptions}*/}
                  {/*/>*/}

                  {/*<SelectInput*/}
                  {/*  rootClass="pt-4 w-1/3"*/}
                  {/*  label="Language"*/}
                  {/*  value={chatBotSetting.botLanguage}*/}
                  {/*  name="botLanguage"*/}
                  {/*  id="botLanguage"*/}
                  {/*  onChange={handleChange}*/}
                  {/*  optionsComp={<>*/}
                  {/*    <option value="" disabled={true}>Select Language</option>*/}
                  {/*    {LANGUAGES.map((language) =>*/}
                  {/*      <option key={language} value={language}>{language}</option>*/}
                  {/*    )}*/}
                  {/*  </>}*/}
                  {/*  required*/}
                  {/*/>*/}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageContainerV2>)
}

export default ActionSettings;
