import {useCallback, useEffect, useRef, useState} from 'react';
import { useNavigate } from "react-router-dom";
import {Modal, Spinner} from "flowbite-react";
import Button from "../../components/button/button";
import TextArea from "../../components/forms/text-area";
import Message from "../../components/toast-message";
import {useAppInfo} from "../../helpers/hooks/common-hook";
import { FaWandMagicSparkles as SparkIcon } from "react-icons/fa6";
import {textToAgentBuild, textToAgentBuildStatus} from "../../services/gen-ai-utils";

const GenerateButton = ({ text, onClick, loading, type = "button" }) => {
    return (
        <button
            type={type}
            onClick={onClick}
            className="
                inline-flex items-center justify-center gap-2 text-sm rounded-md transition h-10 pl-4 pr-5 whitespace-nowrap
                bg-indigo-600 hover:bg-indigo-700 hover:ring-4 ring-indigo-200 dark:ring-indigo-900 text-white"
        >
            {loading ?
                <Spinner size="sm" aria-label="Alternate spinner button example" />
                :
                <>
                    <span className="sr-only">{text}</span>
                    <SparkIcon size={20} />
                </>
            }
            {text}
        </button>
    );
};

function TextToAgentModel({onCompletion, onCancel}) {
    const rootRef = useRef(null);
    const statusCheckIntervalRef = useRef(null);
    const navigate = useNavigate();
    const { appId } = useAppInfo();

    const [waitingForSuccess, setWaitingForSuccess] = useState(false);

    const [formData, setFormData] = useState({
        text: ""
    });

    const [dataLoadingState, setDataLoading] = useState({
        processing: false,
        success: false,
        failed: false,
        message: null
    });

    const [error, setError] = useState({
        text: false
    });

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        setFormData(prev => ({ ...prev, [targetName]: targetValue }));
    }, []);

    const validateForm = () => {
        let valid = true;
        if (!formData.text) {
            setError({ text: true });
            valid = false;
        } else {
            setError({ text: false });
        }

        return valid;
    }

    const updateDataLoadingState = (processing, success, failed, message = null) => {
        setDataLoading({processing, success, failed, message});
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if (!dataLoadingState.processing && validateForm()) {
            updateDataLoadingState(true, false, false);
            setWaitingForSuccess(true);
            const { status } = await textToAgentBuild(appId, formData);
            if (status !== 200) {
                updateDataLoadingState(false, false, true, 'Unable to start process');
            } else {
                checkJobStatus();
            }
        }
    };

    async function checkJobStatus() {
        statusCheckIntervalRef.current = setInterval(async ()=> {
            const { status, response } = await textToAgentBuildStatus(appId);

            if (status === 200 && response) {
                if (response.jobStatus === 'success') {
                    updateDataLoadingState(false, true, false, 'Process completed');
                    clearInterval(statusCheckIntervalRef.current);
                    statusCheckIntervalRef.current = null;
                } else if (response.jobStatus === 'failed') {
                    updateDataLoadingState(false, false, true, 'Failed to initiate AI Agent. Please try again');
                    clearInterval(statusCheckIntervalRef.current);
                    statusCheckIntervalRef.current = null;
                } else if (response.jobStatus === 'in-progress') {
                    setWaitingForSuccess(true);
                }

                if (response.metadata && response.metadata.text) {
                    setFormData({ text: response.metadata.text });
                }
            } else if (status !== 200) {
                // Any other error
                updateDataLoadingState(false, false, false);
            }
        }, 2000)
    }

    useEffect(() => {
        // Start checking status on mount
        updateDataLoadingState(true, false, false);
        checkJobStatus();
        return () => {
            if (statusCheckIntervalRef.current) {
                clearInterval(statusCheckIntervalRef.current);
            }
        }
    }, []);

    useEffect(() => {
        // If success and we were waiting for success, navigate after some time
        if (dataLoadingState.success && waitingForSuccess) {
            const timeoutId = setTimeout(() => {
                navigate(`/app/${appId}/journey`);
                onCompletion();
            }, 2000);
            return () => clearTimeout(timeoutId);
        }

        // If failed, ensure interval is cleared
        if (dataLoadingState.failed && statusCheckIntervalRef.current) {
            clearInterval(statusCheckIntervalRef.current);
        }
    }, [dataLoadingState, waitingForSuccess, navigate, appId, onCompletion]);

    const onAddSample = () => {
        setFormData({
            text: `Create an AI agent to create, delete, and list todo tasks.

# API 
## Task create endpoint
https://65980c48668d248edf2400a2.mockapi.io/api/v1/tasks POST
Sample request:
{ "title": "Test 1", "due_date": "2023-01-12" }

## Task delete endpoint
https://65980c48668d248edf2400a2.mockapi.io/api/v1/tasks/{id} DELETE

## Task list endpoint
https://65980c48668d248edf2400a2.mockapi.io/api/v1/tasks GET
`
        });
    }

    return (
        <div ref={rootRef}>
            <Modal show={true} size="3xl" popup onClose={onCancel} root={rootRef.current ?? undefined}>
                <Modal.Header className="pl-6 pr-3 pt-4">Build with AI</Modal.Header>
                <Modal.Body>
                    <p className="text-sm text-gray-500">Describe your requirement, We'll generate the initial setup for your AI agent.</p>
                    <form onSubmit={handleOnSubmit}>
                        <div className="space-y-6 pt-4">

                            {(waitingForSuccess && dataLoadingState.success) && <Message text="Successfully Initiated AI Agent" type="success"/>}

                            {dataLoadingState.failed && <Message text={dataLoadingState.message} type="failure"/>}

                            <TextArea
                                type="text"
                                name="text"
                                id="text"
                                value={formData.text}
                                placeholder="Example: Create an AI agent to create, delete, and list todo tasks."
                                onChange={handleChange}
                                rows={10}
                                error={error.text}
                            />

                            <div className="flex flex-row justify-between">
                                <div className="w-1/2">
                                    <Button color="default" text="Add Sample" onClick={onAddSample}/>
                                </div>
                                <div className="w-full flex flex-row gap-2 justify-end">
                                    <Button color="default" text="Cancel" onClick={onCancel}/>
                                    {dataLoadingState.processing ?
                                        <GenerateButton text="Processing..." loading={true}/>
                                        :
                                        <GenerateButton type="submit" text="Generate"/>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default TextToAgentModel;
