import { DarkThemeToggle, Tooltip } from "flowbite-react";
import { useEffect, useState } from "react";

import {NavLink, useLocation, useNavigate} from "react-router-dom";
import config from "../../config";
import { publish } from "../../services/app/app-service";
import { getDashboardResponse } from "../../services/billing";
import { getNotifications } from "../../services/notification";

import { useAppInfo } from "../../helpers/hooks/common-hook";
import { getMyAccount } from "../../services/account/account-service";

import { HiBell, HiOutlineBell } from "react-icons/hi";
import { HiChatBubbleOvalLeftEllipsis, HiOutlineChatBubbleOvalLeftEllipsis } from "react-icons/hi2";
import ConfirmDialog from "../../components/confirm-dialog/confirm-dialog";
import Message from "../../components/toast-message";
import DashboardRouter from "../dashboard-router";
import { IconButton, IconHref, Preview, Publish } from "./components/buttons";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import MenuItem from "./components/sidebar/menu-item";
import MenuItemSubmenu from "./components/sidebar/menu-item-submenu";
import { FaWandMagicSparkles as SparkIcon } from "react-icons/fa6";
import { Button } from "flowbite-react";
import {
  appNavigation,
  appNavigationEnd,
  appsNavigation,
  userSettingsNavigation,
} from "./const";
import TextToAgentModel from "../text-to-ai-agent";

export default function DashboardPage() {
  const location = useLocation();
  const appInfo = useAppInfo();
  const navigate = useNavigate();
  // console.log("****appInfo", appInfo);
  const [navigation, setNavigation] = useState([]);
  const [selectedNav, setSelectedNav] = useState(0);
  const [bannerMessage, setBannerMessage] = useState(null);
  const [billingSubscription, setBillingSubscription] = useState({
    status: "active",
    trialEnd: null,
    totalMessages: 0,
  });

  const [notifications, setNotifications] = useState([]);
  const [notificationsFetchState, setNotificationsFetchState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: "",
  });
  const [openConfirmPublishDialog, setOpenConfirmPublishDialog] =
    useState(false);
  const [publishFormState, setPublishFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: "",
  });

  const [openTextToAgentBuild, setOpenTextToAgentBuild] = useState(false);

  const updateFormState = (processing, success, failed, errorMessage) => {
    setNotificationsFetchState(
      Object.assign({}, { processing, success, failed, errorMessage })
    );
  };

  const updatePublishFormState = (processing, success, failed, message) => {
    setPublishFormState(
      Object.assign({}, { processing, success, failed, message })
    );
  };

  async function fetchNotifications() {
    updateFormState(true, false, false);
    const { status, response } = await getNotifications();
    if (status === 200) {
      const { data, pagination } = response;
      setNotifications(data);
    }

    updateFormState(false, false, false);
  }

  async function fetchMyAccount() {
    updateFormState(true, false, false);
    const { account } = await getMyAccount();
    if (!account.billingStatus) {
      setBannerMessage({
        title: "Subscription Not Found",
        description: "Please update your payment details",
        linkText: "Learn more",
        link: "/subscription",
      });
    }

    updateFormState(false, false, false);
  }

  async function fetchSubscription() {
    try {
      updateFormState(true, false, false);
      const { response } = await getDashboardResponse();
      if (response) {
        setBillingSubscription({
          status: response.status,
          trialEnd: response.trialEnd,
          totalMessages: response.upcomingInvoice.invoiceTable.planRow.requests,
        });
      }

      updateFormState(false, false, false);
    } catch (e) {}
  }

  function isUserSettingPath() {
    const { pathname } = location;
    return pathname.includes("user-settings");
  }

  function findRelevantNavigationIndex(navigations, currentPath) {
    try {
      navigations = navigations.map((nav) => nav.href);

      const currentPathParts = currentPath.split("/").filter(Boolean);

      const matchingIndex = navigations.findIndex((nav) => {
        const navParts = nav.split("/").filter(Boolean);

        return navParts.some((part) => currentPathParts.includes(part));
      })
      return matchingIndex;
    } catch (e) {
      return -1;
    }
  }

  async function setupNavigation() {
    const { pathname } = location;
    let navigation;

    if (appInfo && pathname.includes('app')) {
      navigation = appNavigation;
    } else if (isUserSettingPath()) {
      navigation = userSettingsNavigation;
    } else {
      navigation = appsNavigation;
    }

    setSelectedNav(findRelevantNavigationIndex(navigation, pathname));

    setNavigation([...navigation]);
  }

  const initFetch = () => {
    fetchNotifications();
    fetchMyAccount();
    fetchSubscription();
    setupNavigation();
  };

  useEffect(() => {
    initFetch();
  }, []);

  useEffect(() => {
    initFetch();
  }, [appInfo, location]);

  const redirectToWebPlugin = async () => {
    // window.open(
    //   `${config.WEB_PLUGIN_URL}?app_id=${appInfo.appId}&test_mode=1`,
    //   "_blank"
    // );
    navigate(`/app/${appInfo.appId}/agent-preview`);
  };

  const openPublishConfirm = () => {
    setOpenConfirmPublishDialog(true);
  };

  const handleConfirmPublishDialogComplete = async (confirmed) => {
    setOpenConfirmPublishDialog(false);
    if (confirmed) {
      updatePublishFormState(true, false, false);
      const { status } = await publish(appInfo.appId);
      if (status === 200) {
        updatePublishFormState(
          false,
          true,
          false,
          "Successfully published changes"
        );
      } else {
        updatePublishFormState(false, false, true, "Unable to publish changes");
      }
    }
  };

  const handleOpenTextToAgentBuild = () => {
    setOpenTextToAgentBuild(true)
  }

  const handleCloseTextToAgentBuild = () => {
    setOpenTextToAgentBuild(false)
  }

  return (
    <div className="flex h-screen">
      {/* Sidebar Component */}

      {openTextToAgentBuild &&
        <TextToAgentModel
            onCompletion={handleCloseTextToAgentBuild}
            onCancel={handleCloseTextToAgentBuild}/>
      }

      <Sidebar>
        <div className="flex h-full flex-col">
          <div className="h-full flex flex-col gap-0.5 max-h-[75vh] overflow-x-hidden overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
            {navigation.map((item, index) => {
              const submenu = item?.subMenu || false;
              if (submenu) {
                return (
                  <MenuItemSubmenu
                    key={index}
                    icon={item.icon}
                    name={item.name}
                    items={item.subMenuItems}
                  />
                );
              }
              return (
                <MenuItem
                  key={index}
                  active={selectedNav === index}
                  icon={item.icon}
                  name={item.name}
                  href={item.href}
                />
              );
            })}
          </div>
          {/* bottom menu-items */}
          <div className="pb-2 mt-auto">
            {appNavigationEnd.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  icon={item.icon}
                  name={item.name}
                  href={item.href}
                  resetContext={item.resetContext}
                  appPath={false}
                />
              );
            })}
          </div>
        </div>
      </Sidebar>

      {/* Header + Main  */}
      <div className="relative w-full bg-white overflow-y-auto pl-16">
        <Header>
          {appInfo && appInfo.appId && (
            <div className="flex items-center gap-3 pr-3">
              <Preview redirectToWebPlugin={redirectToWebPlugin} />
              <Publish openPublishConfirm={openPublishConfirm} />
            </div>
          )}
          <div className="pr-3 pl-2 flex items-center">
            <span className="w-[3px] h-[3px] rounded-full block bg-slate-400 mr-4" />
            <a
                className="cursor-pointer dark:text-gray-200 dark:hover:text-indigo-500 hover:text-indigo-700 text-gray-600 font-medium transition-colors"
                href="https://docs.botcircuits.com/"
                target="_blank"
            >
              Docs
            </a>
            <span className="w-[3px] h-[3px] rounded-full block bg-slate-400 ml-4" />
          </div>
          {openConfirmPublishDialog && (
            <ConfirmDialog
              open={openConfirmPublishDialog}
              onCompletion={handleConfirmPublishDialogComplete}
              title="Publish Confirmation"
              message="Do you want to publish changes to live ?"
            />
          )}

          {publishFormState.failed && publishFormState.message && (
            <Message text={publishFormState.message} type="failure" />
          )}

          {publishFormState.success && publishFormState.message && (
            <Message text={publishFormState.message} type="success" />
          )}

          <div className="flex items-center gap-2">

            {/*{appInfo && appInfo.appId && (*/}
            {/*    <div className="relative">*/}
            {/*      <IconButton onClick={() => (window.location = "/contact-us")}>*/}
            {/*        <Tooltip content="Build with AI">*/}
            {/*          <SparkIcon size={24} />*/}
            {/*        </Tooltip>*/}
            {/*      </IconButton>*/}
            {/*      /!* Blinking Indigo Dot *!/*/}
            {/*      <div className="absolute top-0 right-0 w-2 h-2 bg-indigo-500 rounded-full animate-ping"></div>*/}
            {/*      <div className="absolute top-0 right-0 w-2 h-2 bg-indigo-500 rounded-full"></div>*/}
            {/*    </div>*/}
            {/*)}*/}

            {/*{appInfo && appInfo.appId && (*/}
            {/*    <IconButton onClick={handleOpenTextToAgentBuild}>*/}
            {/*      <Tooltip content="Build with AI">*/}
            {/*        <SparkIcon size={24} />*/}
            {/*      </Tooltip>*/}
            {/*    </IconButton>*/}
            {/*)}*/}

            <IconButton onClick={() => (window.location = "/contact-us")}>
              <Tooltip content="Contact Us">
                <HiChatBubbleOvalLeftEllipsis size={24} />
              </Tooltip>
            </IconButton>

            <IconHref to="/notifications">
              <Tooltip content="Notifications">
                <HiBell size={24} />
              </Tooltip>
            </IconHref>

            {/* Theme button */}

            <Tooltip content="Theme">
              <DarkThemeToggle theme={{
                'root': {
                  'base': 'p-2 hover:bg-indigo-100 dark:hover:bg-indigo-900 rounded-md transition-all',
                  'icon': 'text-gray-600 dark:text-gray-200 w-6 h-6 hover:text-indigo-700'
                }
              }} />
            </Tooltip>
          </div>
        </Header>
        {/* Main Content */}
        <div className="h-full bg-slate-100 dark:bg-slate-900 container-dark-bg pt-14">
          <DashboardRouter />
        </div>
      </div>
    </div>
  );
}
